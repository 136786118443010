storeApp.controller('scannerController', function($scope, $http) {

	$scope.barcode = '';
	$scope.voucher = null;

	$scope.errorMessage = false;

	$scope.validateBarcode = function(keyEvent){
		if($scope.barcode.length==16 && keyEvent.keyCode==13){
			$http.get('/api/voucher/'+$scope.barcode).then(
				function successCallback(response) {
					if(response.data.voucher){
						$scope.voucher = response.data.voucher;
						$scope.barcode = '';
					} else {
						$scope.errorMessage = response.data.message;
						$scope.barcode = '';
					}
				},
				function errorCallback(response) {
					$scope.errorMessage = response.data.message;
					$scope.barcode = '';
				}
			);
		} else {
			$scope.errorMessage = false;
		}
	}

	$scope.completeVoucher = function(){
		var scanned_at = new Date().toISOString().slice(0, 19).replace('T',' ');
		$http.put('/api/voucher/'+$scope.voucher.id, {scanned_at:scanned_at}).then(
			function successCallback(response) {
				$scope.voucher.scanned_at = scanned_at;
			},
			function errorCallback(response) {
				
			}
		);
	}

});