storeApp.controller('dashboardController', function($scope, $http) {

	/*$scope.pieData = {
    labels: ['Italy', 'UK', 'USA', 'Germany', 'France', 'Japan'],
    datasets: [
        {
            label: '2010 customers #',
            fillColor: '#382765',
            data: [2500, 1902, 1041, 610, 1245, 952]
        }
    ]
};*/
	$scope.myChart = {};
	$scope.stats = {};
	$scope.productData = {
		labels:[],
		datasets:[
			{
				label: '',
				fillColor: '#1981bd',
				data: []
			}
		]
	};

	$http.get('/api/stats').then(
		function successCallback(response) {
			$scope.stats = response.data.stats;
			angular.forEach($scope.stats.products, function(product, id) {
				$scope.productData.labels.push(product.name);
				$scope.productData.datasets[0].data.push(product.num);
			});
			console.log($scope.productData);
			$scope.myChart = new Chart(document.getElementById('productChart').getContext('2d')).Bar($scope.productData);
		},
		function errorCallback(response) {
				
		}
	);

});