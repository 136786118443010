storeApp.controller('historyController', function($scope, $http) {

	$scope.history = [];
	$scope.filteredHistory = [];

	$scope.type = 'all';
	$scope.types = ['error','info','success'];

	$http.get('/api/history').then(
		function successCallback(response) {
			$scope.history = response.data.history
			$scope.filterHistory();
		},
		function errorCallback(response) {
				
		}
	);

	$scope.setType = function(type){
		$scope.type = type;
		if($scope.type=='all') $scope.types = ['error','info','success'];
		else if($scope.type=='error') $scope.types = ['error'];
		else if($scope.type=='info') $scope.types = ['info'];
		else if($scope.type=='success') $scope.types = ['success'];
		$scope.filterHistory();
	}

	$scope.filterHistory = function(){
		$scope.filteredHistory = [];
		angular.forEach($scope.history, function(value, key) {
			if($scope.types.indexOf(value.type)>=0) $scope.filteredHistory.push(value);
		});
	}

});